.radioButton {
  margin-bottom: 2rem;

  label {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 20px;
    position: relative;

    input {
      opacity: 0;
      position: absolute;

      &:checked~.design {
        outline-color: white;
        background: white;
      }
    }

    .design {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      outline-offset: 3px;
      outline: 3px solid white;
      margin-right: 1.5rem;
    }
  }
}