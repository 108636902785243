.checkBox {
  display: flex;
  height: 36px;
  column-gap: 16px;
  margin-bottom: 1rem;

  .checkBoxContainer {
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    user-select: none;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 32px;
      width: 32px;
      background-color: transparent;
      border: 2px solid white;
      border-radius: 4px;
    }

    input:checked ~ .checkmark {
      background-color: white;
    }

    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    input:checked ~ .checkmark:after {
      display: block;
    }

    .checkmark:after {
      left: 12px;
      width: 8px;
      height: 20px;
      border: solid black;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
    }
  }

  .label {
    display: flex;
    align-items: center;
    
    span {
      font-size: 16px;
      letter-spacing: 0.5px;
      font-weight: 400;
      color: white;
    }
  }
}