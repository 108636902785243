.footer {
  grid-area: footer;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 26px;
  
  .time {
    width: 145px;
    
    span {
      font-weight: 400;
      font-size: 14px;
    }
  }
  
  .title {
    
    span {
      font-size: 16px;
      font-weight: 700;
      margin-right: 100px;
    }
  }
  
  .logo {
    
    span {
      font-size: 26px;
      font-weight: 700;
    }
  }
}