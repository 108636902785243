@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import "variables";

:root {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  
  
  body {
    margin: 0;
    padding: 0;
    background-color: $background_color;
    box-sizing: border-box;

    user-select: none;

    h1, h2, h3, h4, h5, h6 {
      margin: 0;
      padding: 0;
    }

    ul {
      margin: 0;
      padding: 0;
    }

    p {
      margin: 0;
      padding: 0;
    }
    
    li {
      list-style-type: none;
    }
    
    .button {
      height: 57px;
      width: 166px;
      border-radius: 10px;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 1px;
      cursor: pointer;
    }

    .MuiTabs-indicator {
      display: none;
    }
  }
}

*::-webkit-scrollbar {
  height: 25px;
  width: 25px;
}
*::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  border-radius: 15px;
  background-color: white;
  border: 5px solid #06324C;
}