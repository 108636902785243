@import "../../assets/variables";

.homeKitchen {
  display: grid;
  width: 100vw;
  grid-template-columns: 128px calc(100vw - 128px);
  grid-template-rows: calc(100vh - 60px) 60px;
  grid-template-areas: 
      "sideBar kitchenTabs"
      "sideBar footer";
  
  .container {
    grid-area: kitchenTabs;
    margin-top: 0.5rem;
    display: grid;
    grid-template-columns: 1fr 4fr;
    
    .column {
      width: 100%;
      overflow: hidden;
      display: grid;
      grid-template-rows: auto 1fr;
      grid-template-areas: 
          "tabs"
          "content";
      

      .tabs {
        grid-area: tabs;
        display: flex;
        justify-content: center;
        width: 100%;

        .tabButtons {
          display: flex;
          justify-content: flex-start;
          column-gap: 3rem;
          margin-bottom: 6.5px;

          .tab {
            height: 75%;
            font-weight: 700;
            font-size: 24px;
            color: white;
            padding: 10px 50px;
            border: 2px solid white;
            border-radius: 8px 8px 0 0;
            text-align: center;
            cursor: pointer;
            z-index: 9999;
            background-color: #06324C;
            border-bottom: none;
          }

          .notActiveTab {
            border-radius: 8px;
            height: inherit;
            background-color: #A9B4BE;
            color: black;
            border: none;
          }
        }
        
        @media screen and (max-width: 1366px) {
          .tabButtons {
            column-gap: 1rem;
            margin-bottom: 10.5px;

            .tab {
              height: 75%;
              font-weight: 700;
              font-size: 16px;
              color: white;
              padding: 10px 40px;
              border: 2px solid white;
              border-radius: 8px 8px 0 0;
              text-align: center;
              cursor: pointer;
              z-index: 9999;
              background-color: #06324C;
              border-bottom: none;
            }

            .notActiveTab {
              border-radius: 8px;
              height: inherit;
              background-color: #A9B4BE;
              color: black;
              border: none;
            }
          }
        }
      }

      .tabContent {
        grid-area: content;
        border: 2px solid white;
        overflow: hidden;
        overflow-y: auto;
        
        .content {
          padding: 10px;
          overflow: hidden;
          display: grid;
          justify-items: center;
          row-gap: 1rem;
        }
      }

      &.rightSide {
        
        .tabs {
          justify-content: flex-start;
          margin-left: 4rem;
        }

        .tabContent {

          .content {
            padding: 10px 5px 10px 10px;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-row-end: 3;
            column-gap: .8rem;
          }
        }

        @media screen and (max-width: 1366px) {
          .tabs {
            justify-content: flex-start;
            margin-left: 2rem;
          }
        }
      }
    }
  }
}

.homeHall {
  padding: 0 59px;
  height: calc(100vh - 60px);
  display: grid;
  grid-template-rows: 1fr 59px;
  grid-template-areas: 
      "hallDisplay"
      "footer";
  
  .container {
    grid-area: hallDisplay;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 1rem;
    
    div {
      overflow: hidden;
      overflow-y: scroll;
      ::-webkit-scrollbar {
        display: none;
      }
    }
  }
  
}