@import "src/assets/variables";

.ordersContent {
  border: 2px solid white;
  width: calc(100vw - 132px);
  height: calc(100vh - 70px);
  overflow: hidden;
  overflow-y: scroll;
  
  .content {
    padding: 10px;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(5, 1fr);
    grid-auto-flow: dense;
    align-content: start;
  }
}

.tab {
  color: white;
  width: fit-content;
  border-radius: 8px 8px 0 0;
  border: 2px solid white;
  border-bottom: none;
  padding: 10px 50px;
  z-index: 9999;
  position: absolute;
  top: 16px;
  left: 1rem;
  background-color: black;
  
  span {
    font-size: 24px;
    font-weight: 700;
  }
}