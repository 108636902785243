.sideBar {
  grid-area: sideBar;
  background-color: #106DA2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  .upSideButtons {
    margin-top: 66px;
  }
  
  .downSideButtons {
    
    .buttonItem:last-child {
      border-bottom: none;
    }

    .buttonItem:first-child {
      padding: 0 2.5rem;
    }
  }
  
  .buttonItem {
    height: 128px;
    border: 2px solid white;
    border-left: none;
    border-right: none;
    background-color: transparent;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.25px;
    cursor: pointer;
    padding: 0 .5rem;
    
    svg {
      margin-bottom: 16px;
      width: 35px;
      height: 35px;
    }

    &:last-child {
      border-top: none;
    }
    
    &.active {
      background-color: black;
    }
  }
}