.sideBarWindow {
  background-color: black;
  width: calc(100vw - 128px);
  height: calc(100vh - 66px);
  position: absolute;
  top: 0;
  left: 128px;
  z-index: 9999;
  color: white;
  display: flex;
  align-items: center;
  padding-top: 66px;
  flex-direction: column;
  
  .container {
    width: 505px;
    
    .buttons {
      display: flex;
      flex-direction: column;
      
      button {
        border-radius: 8px;
        color: white;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.5px;
        height: 48px;
        cursor: pointer;
        
        &:first-child {
          background-color: #106DA2;
          border: none;
          margin-bottom: 32px;
        }
        
        &:last-child {
          background-color: transparent;
          border: 2px solid white;
        }
      }
    }
  }
}

.finishedOrders {
  background-color: black;
  width: calc(100vw - 128px);
  height: calc(100vh - 66px);
  position: absolute;
  top: 0;
  left: 128px;
  z-index: 9999;
  color: white;
  display: flex;
  align-items: center;
  padding-top: 66px;
  flex-direction: column;
}