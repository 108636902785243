.column {
  
  .title {
    padding: 24px 0 18px 0;
    
    h1 {
      font-weight: 700;
      font-size: 40px;
      letter-spacing: 0.8px;
      color: white;
    }
  }
  
  .container {
    overflow: hidden;
    overflow-y: scroll;
    background-color: #E5E5E5;
    border-radius: 8px 8px 0 0;
    height: calc(100vh - 150px);
    
    .card {
      background-color: white;
      margin-bottom: 12px;
      border-radius: 8px;
      padding: 24px 30px 30px 30px;
      
      &:last-child {
        margin-bottom: 0;
      }
      
      .selectedPlace {
        display: flex;
        align-items: center;
        column-gap: 16px;
        
        h4 {
          color: #06324C;
          font-size: 24px;
          font-weight: 700;
          letter-spacing: -0.48px;
        }
        
        li {
          font-size: 14px;
          font-weight: 600;
          letter-spacing: 0.64px;
          border-radius: 16px;
          padding: 2px 10px;
          
          &.away {
            background-color: #06324C;
            color: white;
          }
          
          &.inside {
            background-color: #FEDCA9;
            color: #06324C;
          }
        }
      }
      
      .timeAndNumber {
        margin: 14px 0 30px 0;
        
        li {
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 0.73px;
          color: #4A4A4A;
        }
      }

      .productName {
        display: flex;
        flex-direction: column;
        
        span {
          font-size: 16px;
          font-weight: 700;
          letter-spacing: 0.73px;
          color: #363641;
          margin-bottom: 8px;
          
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      
      .buttons {
        font-size: 16px;
        font-weight: 600;
        line-height: 31px;
        display: flex;
        justify-content: space-between;
        margin-top: 40px;
        
        button:first-child {
          background-color: white;
          border: 2px solid #0070B5;
          color: #0070B5;
        }

        button:last-child {
          background-color: #0070B5;
          border: 2px solid #0070B5;
          color: white;
        }
      }
    }
  }
  
  .hallContainer {
    overflow: hidden;
    overflow-y: scroll;
    background-color: #E5E5E5;
    border-radius: 8px 8px 0 0;
    height: calc(100vh - 150px);
    
    .content {
      padding: 1rem;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;

      .cardHall {
        background-color: white;
        border-radius: 8px;
        text-align: center;

        .number {

          li {
            color: black;
            font-size: 60px;
            font-weight: 700;
            letter-spacing: -0.5px;
          }
        }
      }
    }
  }
}