@import "src/assets/variables";

.kitchenCard {
  border-radius: 8px;
  max-width: 326px;
  width: 100%;
  height: min-content;
  overflow: hidden;

  &.takeAway {
    border: 2px solid #63A9FF;
  }
  
  &.frontCounter {
    border: 2px solid #97DD40;
  }
  
  &.curbSide {
    border: 2px solid #B894FF;
  }
  
  &.tableService {
    border: 2px solid #FF7820;
  }

  &.tableServing {
    border: 2px solid #FFC60C;
  }

  &.takeAwayTableServing {
    border: 2px solid #4BD7FB;
  }

  .header {
    color: black;
    padding: 13px 10px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    &.takeAway {
      background-color: #63A9FF;
    }

    &.frontCounter {
      background-color: #97DD40;
    }

    &.curbSide {
      background-color: #B894FF;
    }

    &.tableService {
      background-color: #FF7820;
    }

    &.tableServing {
      background-color: #FFC60C;
    }

    &.takeAwayTableServing {
      background-color: #4BD7FB;
    }

    .number {
      font-size: 24px;
      font-weight: 700;
      display: flex;
      flex-direction: column;
      
      span:last-child {
        font-weight: 400;
        font-size: 16px;
      }
    }
    
    .deliveryMethod {
      display: flex;
      flex-direction: column;
      margin-top: 5px;
      
      span {
        font-size: 16px;
        letter-spacing: 0.5px;
        font-weight: 400;
        align-self: center;
      }
      
      @media screen and (max-width: 1366px) {
        span {
          font-size: 14px;
          letter-spacing: 0;
        }
      }
    }
    
    .collapseButton {
      
      button {
        cursor: pointer;
        width: 52px;
        height: 52px;
        border-radius: 8px;
        border: 2px solid white;
        background-color: #06324C;
        
        svg {
          color: white;
        }
      }

      .backBtnInFinishedOrders {
        background-color: black;
      }

      @media screen and (max-width: 1366px) {
        button {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
  
  .content {
    padding: 13px 10px;
    
    .productsData {
      display: flex;
      flex-direction: column;
      
      .productLine {
        display: flex;
        justify-content: space-between;
        height: fit-content;
        margin-bottom: 8px;

        .productName {
          display: flex;
          column-gap: 20px;
          color: white;
          margin-bottom: 8px;
          
          align-items: center;
          
          span {
            font-size: $card_default_font_size;
            
            @media screen and (max-width: 1366px) {
              font-size: $card_small_font_size;
            }
          }
        }

        .includedProduct {
          margin-left: 1.5rem;
          font-size: 16px;
          margin-bottom: 0px;
        }

        .notIncludedProduct {
          margin-left: 3rem;
          font-size: 16px;
          margin-bottom: 0px;
        }

        .checkBoxContainer {
          position: relative;
          padding-left: 35px;
          cursor: pointer;
          user-select: none;

          input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
          }

          .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 26px;
            width: 26px;
            background-color: transparent;
            border: 2px solid white;
            border-radius: 4px;
          }

          input:checked ~ .checkmark {
            background-color: transparent;
            border: 2px solid #6DD400;
          }

          .checkmark:after {
            content: "";
            position: absolute;
            display: none;
          }

          input:checked ~ .checkmark:after {
            display: block;
          }

          .checkmark:after {
            left: 9px;
            top: 2px;
            width: 5px;
            height: 15px;
            border: solid #6DD400;
            border-width: 0 3px 3px 0;
            transform: rotate(45deg);
          }
        }
      }
    }
  }
  
  .contentHidden {
    display: none;
  }

  .buttons {
    padding: 13px 10px;
    display: flex;
    justify-content: space-between;
    
    button {
      cursor: pointer;
      padding: 10px 25px;
      color: white;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0.5px;
      background-color: #06324C;
      border: 2px solid white;
      border-radius: 8px;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      
      svg {
        font-size: 25px;
      }
    }
    
    .backBtnInFinishedOrders {
      background-color: black;
    }

    &.onButtonDiv {
      display: flex;
      justify-content: flex-end;
    }
  }
  
  @media screen and (max-width: 1366px) {
    .buttons {
      padding: 13px 10px;
      display: flex;
      justify-content: space-between;

      button {
        cursor: pointer;
        padding: 8px 10px;
        color: white;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.5px;
        background-color: #06324C;
        border: 2px solid white;
        border-radius: 8px;
        text-transform: uppercase;
        display: flex;
        align-items: center;

        svg {
          font-size: 25px;
        }
      }

    }
  }
  
  .buttonsHidden {
    display: none;
  }
}